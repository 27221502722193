<div [ngClass]="layoutClass">
  <a7-moving-number *ngIf="aType === 'number'"
                    [ngClass]="aClass"
                    [number]="aText"
                    [decimalPlaceClass]="decimalPlaceClass">
  </a7-moving-number>

  <div *ngIf="aType !== 'number'"
       [ngClass]="aClass">{{aText}}
  </div>

  <div [ngClass]="bClass">{{bText}}
    <span *ngIf="bTooltip"
          [cTip]="bTooltip">
      <fa-icon [icon]="['far', 'question-circle']"> </fa-icon>
    </span>
  </div>
</div>
