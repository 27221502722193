// tslint:disable:max-line-length

export const TERMS = `
The Ark7 Referral Program (the “Program”) is offered by Ark7 Inc. (“Ark7”, “we” or “us”) as a reward for our loyal users
(“Referrer(s)” or “You”) and your friends, family, and connections (“Referee(s)” or “You”). We reserve the right to
amend or terminate the Program at any time, for any reason. The Program is administered by Ark7.

These terms and conditions ("Terms") apply to your participation in the Program. By participating in the Program, you
agree to use the Program as outlined herein, and consistent with any other terms we may apply to the Program. You
further agree with Ark7 contacting you about the Program via email and app notifications. You may not participate in the
Program where doing so would be prohibited by any applicable law or regulations.

The Program consists of limited-time promotional offerings. Please review the offerings below to see which promotions
are available to you under the Program at the time you make referrals.

1. Promotion Period
<ol type="a">
  <li>The referral program begins at 12:00 AM PT on January 1, 2024, and ends at 11:59 PM PT on December 31, 2025.</li>
</ol>
<br />
2. Eligibility
<ol type="a">
  <li>Referrer: To participate in the referral program, you must be at least 18 years old, a legal U.S. resident, and have an existing, verified Ark7 account. This allows you to refer friends and family to become investors with Ark7.</li>
  <li>Referee: Anyone who signs up through your referral link must be at least 18 years old, a legal U.S. resident, and must not already have an existing Ark7 investor account.</li>
</ol>
<br />
3. Referral Bonus
<ol type="a">
  <li>Each time someone becomes a new Ark7 investor by setting up an Ark7 account using your Referral Link (as defined below) during the Promotional Period and completing the required purchase, you, as the referrer, will receive a Referral Bonus. The details of the bonus will be provided in the promotion message within the Ark7 app or other Ark7 channels. 
  </li>
  <li>There’s no limit to the number of referees you can refer. The more people you refer, the more referral bonuses you can earn.</li>
  <li>Each referee who completes the setup of their Ark7 account and makes a Qualified Investment will receive a New Investor Bonus, thanks to your referral. The details of the New Investor Bonus will be provided in the promotion message within the Ark7 app or other Ark7 channels accessible to the referee. The New Investor Bonus will be deposited directly into the referee's Ark7 account.</li>
  <li>The Referral Bonus and the Referee's New Investor Bonus are non-transferable, non-negotiable, and can only be used to invest in Ark7 offerings through the owner's Ark7 account. These bonuses cannot be withdrawn from the owner's Ark7 account.</li>
</ol>
<br />
4. The Program
<ol type="a">
  <li>Referrers are subject to these Terms:<ol type="i">
      <li>Referrers will be provided with a unique referral link or code (a “Referral Link”) from their Ark7 accounts,
        which they can share with Referees. Referrers must share their Referral Link with Referees during the Promotion
        Period, after which the Referral Link will no longer work.</li>
      <li>Subject to subsection iii below, if a Referee correctly follows the steps set out in section 4.b, as the referrer, you will receive a referral bonus.</li>
    </ol>
  </li>
  <li>Referees are subject to these Terms:<ol type="i">
      <li>Upon receiving a Referral Link from the Referrer, the Referee must register and open an Ark7 account and apply
        the Referral Link during the Program Period.</li>
      <li>If a Referral Link is incorrectly applied or is applied outside the Program Period, neither the Referee nor
        their Referrer will receive the bonuses.</li>
      <li>After a Referee has correctly applied a Referral Link, if the Referee makes a Qualifying Investment, the
        Referee will receive a New Investor Bonus.</li>
    </ol>
  </li>
</ol>
<br />
5. Delivery of Referral Bonus
<ol type="a">
  <li>The Referral Bonus is determined solely at Ark7’s sole discretion.
  </li>
  <li>Referrers will receive their Referral Bonus once Ark7 has performed the necessary risk and abuse checks. Ark7
    reserves the right to cancel any Referral Bonus without notice if it determines Referrer engaged in fraud or
    abuse of the Program.</li>
  <li>You will receive your applicable Referral Bonus for each successful Referral within five (5) business days
    (which includes, for the avoidance of doubt, your satisfaction with the referee's Qualified Investment).</li>
  <li>You must have an active Ark7 account in order to receive your Referral Bonus in your Ark7 account.</li>
  <li>Your Ark7 account must not be canceled or past due at the time of fulfillment of any Referral Bonus.</li>
</ol>
<br />
6. Other important information
<ol type="a">
  <li>We may (a) stop you from participating in the Program or claiming a Referral Bonus; (b) revoke any Referral Bonus; (c) block you from participating in future Programal activities; and/or (d) suspend your Ark7
    account, in each case if it considers, in its reasonable opinion, that you are acting contrary to these Terms, the
    spirit of these Terms and/or that you are unfairly taking advantage of or are abusing the Program in any way. To the
    fullest extent permitted by law, we will not be liable for any loss or damage arising from your participation in the
    Program or from your enjoyment of a Referral Bonus.</li>
  <li>You will be solely responsible for all federal, state, and local taxes, and for any other fees or costs associated
    with all bonuses they receive, regardless of whether the Referral Bonus, in whole or in part, is used. We do not
    take responsibility for any tax related to any Referral Bonus you receive. Consult with your tax advisor about
    the appropriate tax treatment and any tax implications associated with the receipt of a Referral Bonus before
    participating.</li>
  <li>We reserve the right at any time to cancel, change or replace the Program (including the alteration of a Referral Bonus) due to:<ol type="i">
      <li>any reason outside of its reasonable control; and/or</li>
      <li>the technical operation of software for any reason operating in a way that is materially different to the
        purpose of the Program, including by distributing more Referral Bonus than the number of available Referral Bonus.</li>
    </ol>
  </li>
</ol>
<br />
7. Limitation of Liability & Disclaimer of Warranties
<ol type="a">
  <li>IN NO EVENT WILL ARK7 OR ITS AFFILIATES, SUBSIDIARIES AND RELATED COMPANIES, OR THEIR RESPECTIVE OFFICERS,
    DIRECTORS, EMPLOYEES, REPRESENTATIVES AND AGENTS, BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND,
    INCLUDING DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR PARTICIPATION IN THE
    PROGRAM OR FOR ANY ACTION OR OMISSION MADE IN CONNECTION WITH THE PROGRAM. WITHOUT LIMITING THE FOREGOING,
    EVERYTHING IN THESE RULES AND IN THIS PROGRAM, INCLUDING THE REFERRAL BONUS AWARDED, IS PROVIDED “AS IS” WITHOUT
    WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. SOME JURISDICTIONS MAY NOT ALLOW THE
    LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR EXCLUSION OF IMPLIED WARRANTIES SO
    SOME OF THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR
    LIMITATIONS REGARDING THESE LIMITATIONS OR EXCLUSIONS.</li>
</ol>
<br />
8. Governing Law
<ol type="a">
  <li>Except where prohibited, disputes, claims, and causes of action arising out of or related to this Program shall be
    resolved under the laws of the United States, and except where prohibited, the laws of California (without reference
    to its conflicts of laws principles), and you agree to submit any dispute to the exclusive jurisdiction of the state
    and federal courts located in San Francisco County, California.</li>
</ol>
<br />
9. Miscellaneous
<ol type="a">
  <li>No waiver of any of the provisions of these Terms shall be deemed or shall constitute a waiver of any other
    provisions hereof, nor shall waiver constitute a continuing waiver unless otherwise expressly provided. If any
    provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, such
    provision shall be severed from the remainder of these Terms, which will otherwise remain in full force and effect.
  </li>
</ol>

If you have any questions about these Terms, please contact us at:`;
