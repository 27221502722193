<app-page>
  <div class="site-container ark7-resource ds-delight">
    <div class="h1">ARK7 REFERRAL PROGRAM TERMS AND CONDITIONS</div>
    <br />

    <markdown [data]="terms"></markdown>

    <pre class="address">Ark7 Inc.
1 Ferry Building, Ste 201
San Francisco, CA 94111
United States
Attention: Ark7 Legal</pre>
  </div>
</app-page>
