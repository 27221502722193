import { Component, Input } from '@angular/core';

@Component({
  selector: 'v2-ab-block',
  templateUrl: './ab-block.component.html',
  styleUrls: ['./ab-block.component.scss'],
})
export class AbBlockComponent {
  @Input()
  layoutClass = 'flex-column-between-start';

  @Input()
  aType = 'number';

  @Input()
  aClass = 'h1';

  @Input()
  decimalPlaceClass = 'h1';

  @Input()
  bClass = 'label-2 color-green-grey-3';

  @Input()
  aText = '';

  @Input()
  bText = '';

  @Input()
  bTooltip = undefined;
}
