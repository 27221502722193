import {
  A7LayoutService,
  A7PageSizeMatcher,
  V2BaseComponent,
} from '@ark7/layout';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'v2-page-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends V2BaseComponent implements OnInit {
  @Input() maxWidth = 'v2-max-w-102';
  @Input() topPadding = 'v2-padding-18-t';
  @Input() host = 'https://ark7.com';
  @Input() showNavi = true;
  @Input() showAppDisclaimer = false;

  @A7PageSizeMatcher('SM-') sm;

  @Input()
  showTermsApply = false;

  handset: boolean = false;

  constructor(breakpointObserver: BreakpointObserver, l: A7LayoutService) {
    super(l);
    breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe((result) => {
        this.handset = result.matches;
      });
  }

  ngOnInit() {}
}
