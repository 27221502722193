import _ from 'underscore';
import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import {
  AnonCarrierResource,
  AppConfigurationService,
  TrackingService,
} from '@ark7/resources-common';
import { Carrier } from '@ark7/core-business-models';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'v2-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends V2BaseComponent implements OnInit {
  @LocalStorage()
  hideDownloadAppBanner: boolean;

  @LocalStorage()
  hidePromoBanner: boolean;

  carriers: Carrier[];

  constructor(
    l: A7LayoutService,
    @Inject(PLATFORM_ID) private platformId: string,
    private carrierResource: AnonCarrierResource,
    public trackingService: TrackingService,
    private configurationService: AppConfigurationService,
  ) {
    super(l);
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.carrierResource
        .query({
          tags: 'trending',
        })
        .subscribe((carriers) => {
          this.carriers = _.first(carriers, 5);
        });
    } else {
      this.carriers = [];
    }
  }

  get downloadQueryParams() {
    return {
      r: this.trackingService.referrer,
      tc: this.trackingService.trackingCode,
    };
  }

  closeDownloadAppBanner() {
    this.hideDownloadAppBanner = true;
  }

  get showDownloadAppBanner() {
    return !this.hideDownloadAppBanner && this.sm;
  }

  closePromoBanner() {
    this.hidePromoBanner = true;
  }

  get showPromoBanner() {
    return !this.hidePromoBanner && !this.sm && this.showPromoMessage;
  }

  get promoBannerMessage() {
    return this.configurationService.getConfig(
      'web.landing.promoBannerMessage',
    );
  }
  get showPromoMessage() {
    return !_.isEmpty(
      this.configurationService.getConfig('web.landing.promoBannerMessage'),
    );
  }
  get showAppDisclaimer() {
    return this.configurationService.landingHeroTitle.includes('#1');
  }
}
